import { useMySWR } from "../http/use-swr";
import { GenericResponse } from "../generic/GenericResponse";
import { ParkingPriceDTO } from "../../model/parkingPrice/ParkingPriceDTO";
import { Nully } from "react/constants/nully";

export const useParkingPriceForUserInBay = (parkId: Nully<number>, bayId: Nully<number>) => {
    return useMySWR<GenericResponse<ParkingPriceDTO>>(
        parkId && bayId ? `parks/${parkId}/parking-prices/user/${bayId}` : null
    );
};
